import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom'
import useAuth from './hooks/useAuth';
import { Alert, Container } from 'reactstrap';

export default function RequireAdmin({ children }) {
    const [isLoading, setIsLoading] = useState(true);
    const [isUserAdmin, setIsUserAdmin] = useState(false);
    const { isAdmin } = useAuth();

    useEffect(() =>
        isAdmin()
            .then(res => {
                setIsUserAdmin(res.data)
            })
            .catch(() => {
                setIsUserAdmin(false)
            })
            .finally(() => {
                setIsLoading(false)
            }), []);

    if (isLoading)
        return <div />

    return isUserAdmin === true
        ? children 
        : <Container><Alert color="danger">This page requires admin access. If you are a Monarch Employee and believe that you should have access to this page, please put in an IT Support Request on the Employee Website</Alert></Container>;
} 