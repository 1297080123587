import React, { useState, useContext, createContext } from "react";
import { useLocation, useNavigate } from 'react-router-dom'
import { logDataEditAction, axios } from 'react-mimg'
const authContext = createContext();

function useProvideAuth() {
    const [error, setError] = useState('');
    const navigate = useNavigate();
    let location = useLocation();

    function isAuthenticated() {
        return new Promise(res =>
            axios.get('api/login/authenticated')
                .then(() => {
                    res({ data: true })
                })
                .catch(() => {
                    res({ data: false })
                })
        );
    }

    function isAdmin() {
        return new Promise(res =>
            axios.get('api/login/IsAdmin')
                .then((r) => {
                    res({ data: r.data })
                })
                .catch(() => {
                    res({ data: false })
                })
        );

    }

    function signIn(username, password) {
        return new Promise(res => {
            axios.post('api/login/login', { username: username, password: password })
                .then(loginRes => {
                    if (loginRes.data.isTemporaryPassword) {
                        navigate('/resetPassword?token=' + loginRes.data.resetPwdToken);
                    } else {
                        logDataEditAction({
                            procedure: 'Login',
                            message: username + ' logged in.'
                        })
                        
                        res(loginRes)
                    }
                })
                .catch(error => {
                    if (error.response && error.response.status === 400) {
                        setError(error.response.data)
                    }
                    else {
                        setError('Unexpected error when signing in. Please try again.');
                    }
                })
        })
    }

    function signOut() {
        return new Promise((res) => {
            axios.post('api/login/logout') //secure cookies can only be modified from the server (including delete)
                .then(response => {
                    if (location.pathname !== '/login') {
                        navigate('/login', { replace: true })
                    }
                    res();
                });
        });
    }

    return {
        isAuthenticated,
        isAdmin,
        signIn,
        signOut,
        error,
        clearError() {
            setError('')
        }
    };
}

export function AuthProvider({ children }) {
    const auth = useProvideAuth();

    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export default function useAuth() {
    return useContext(authContext);
}