import React from 'react';
import { logError, ErrorLogModel} from 'react-mimg'
import { ErrorPage } from './ErrorPage';


export class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error) {
        this.setState({ hasError: true });
        logError(new ErrorLogModel(error, "Global Error", "An error was caught by the error boundary"));
    }

    render() {
        if (this.state.hasError) {
            return (
                <ErrorPage/>
            )
        }
        return this.props.children;
    }
}