import React, { useState, useEffect } from 'react';
import { Navigate, useParams } from 'react-router-dom'
import useAuth from './hooks/useAuth';

export default function RequireAuth(props) {
    const [isLoading, setIsLoading] = useState(true);
    const [isAuthed, setIsAuthed] = useState(false);
    const { isAuthenticated } = useAuth();
    let urlOnFail = props.goto ? "/login?type=" + (props.type ? props.type : "investors") + "&goto=" + props.goto : "/login";
    const params = useParams();

    if (parseInt(params.id) > 0) {
        urlOnFail = urlOnFail + "&id=" + params.id;
    }

    useEffect(() =>
        isAuthenticated()
            .then(res => {
                setIsAuthed(res.data)
            })
            .catch(() => {
                setIsAuthed(false)
            })
            .finally(() => {
                setIsLoading(false)
            }), []);

    if (isLoading)
        return <div />

    return isAuthed === true ? props.children : <Navigate to={urlOnFail} replace />;
} 