import React, { lazy, Suspense } from 'react';
import { isIE } from 'react-device-detect';
import LazyLoad from 'react-lazyload'
import styles from './app.module.scss'
import Router from './Router';
import NavigationBar from './components/navigation/NavigationBar';
import 'react-mimg/dist/index.css'

const IEnotSupported = lazy(() => import('./pages/Error Handling/IEnotSupported'));
const Footer = lazy(() => import('./components/footer/Footer'));

//ReactGA.initialize('G-TV122MCKT3');

export default function App() {
    require('es6-object-assign').polyfill();

    if (isIE) {
        return (
            <Suspense fallback={<div />}>
                <IEnotSupported />
            </Suspense>);
    }
    return (
        <div className={styles.wrapper} >
            <NavigationBar />
            <div className={styles.content}>
                <Router />
            </div>
            <Suspense fallback={<div />}>
                <LazyLoad offset={200} once>
                    <Footer />
                </LazyLoad>
            </Suspense>
        </div>
    );
}
