import React, { useEffect, Suspense, lazy } from 'react'
import { Route, Routes, useLocation, Navigate } from "react-router-dom";
import RequireAuth from './RequireAuth';
import RequireAdmin from './RequireAdmin';


const Login = lazy(() => import('./pages/Login/Login'))
const Register = lazy(() => import('./pages/Login/Register'))
const ResetPassword = lazy(() => import('./pages/Login/ResetPassword'))
const InvestorRedirect = lazy(() => import('./pages/InvestorRedirect'))
const Jobs = lazy(() => import('./pages/Careers/Jobs'))
const Default = lazy(() => import('./pages/Default/Default'))
const ContactUs = lazy(() => import('./pages/ContactUs/ContactUs'))
const Career = lazy(() => import('./pages/Careers/Career'))
const About = lazy(() => import('./pages/About/about'))
const Properties = lazy(() => import('./pages/Properties/Properties'))
const Residents = lazy(() => import('./pages/Residents/ResidentServices'))
const Presentations = lazy(() => import('./pages/Investors/Presentations'))
const TransferRequest = lazy(() => import('./pages/Investors/TransferRequest'))
const TransferRequestQueue = lazy(() => import('./pages/Investors/TransferRequestQueue'))
const MyK1s = lazy(() => import('./pages/Investors/MyK1s'))
const DataSyncing = lazy(() => import('./pages/Admin/DataSyncing'))
const NotFoundPage = lazy(() => import('./pages/Error Handling/NotFoundPage'))
const UserMaint = lazy(() => import('./pages/Admin/UserMaint'))
const DocumentViewsReport = lazy(() => import('./pages/Admin/DocumentViewsReport'))
const InvestorChangeProcessing = lazy(() => import('./pages/Admin/InvestorChangeProcessing'))
const TransactionHistory = lazy(() => import('./pages/Investors/TransactionHistory'))
const Investments = lazy(() => import('./pages/Investors/Investments'))
const InvestorLetters = lazy(() => import('./pages/Investors/InvestorLetters'))
const DownloadSiteTour = lazy(() => import('./pages/Investors/DownloadSiteTour'))
const Ledger = lazy(() => import('./pages/Investors/Ledger'))
const Reports = lazy(() => import('./pages/Investors/Reports'))
const ManageMyAccount = lazy(() => import('./pages/Investors/ManageMyAccount'))
const AddPropertyDocument = lazy(() => import('./pages/Admin/AddPropertyDocument'))
const SendDocusign = lazy(() => import('./pages/Admin/SendDocusign'))
const QuarterlyReports = lazy(() => import('./pages/Investors/QuarterlyReports'))
const PaymentProcessingAdmin = lazy(() => import('./pages/Admin/PaymentProcessingAdmin'))
const Square = lazy(() => import('./pages/Payments/Square'))
const AcquisitionHistoryPage = lazy(() => import('./pages/About/AcquisitionHistoryPage'))
const CompanyResumePage = lazy(() => import('./pages/About/CompanyResumePage'))

export default function Router() {
    const location = useLocation();

    //useEffect(() => {
    //    //log the page view in the logging table and activity table
    //    axios.post('api/log/pageView').then((response) => {
    //        let logId = response.data.logId;
    //        let logObject = getLogObjectFromStorage();
    //        if (logObject) {
    //            if (moment(logObject.currentSessionLogTime).add(1, 'hour') < moment()) { //last log was more than 1 hour ago - set new "session"
    //                logObject.currentSessionFirstPageLoadId = logId;
    //            }
    //        }
    //        else {
    //            logObject = {
    //                firstEverPageLoadId: logId,
    //                currentSessionFirstPageLoadId: logId
    //            }
    //        }
    //        logObject.currentPageLoadId = logId;
    //        logObject.currentSessionLogTime = new Date().getTime();
    //        logObject.actionType = "Page Load";
    //        logObject.actionDetails = JSON.stringify({
    //            pathname: location.pathname,
    //            search: location.search
    //        })
    //        localStorage.setItem('monarchBrowserStorage', CryptoJS.AES.encrypt(JSON.stringify(logObject), process.env.REACT_APP_LOCAL_STORAGE_KEY).toString())
    //        axios.post('api/log/LogActivity', logObject)
    //    })
    //}, [location]);

    //useEffect(() => { //log page views to GA in production
    //    if (process.env.NODE_ENV && process.env.NODE_ENV === 'production') {
    //        var page = location.pathname.toLowerCase() + location.search.toLowerCase();
    //        ReactGA.send("pageview", page);
    //    }
    //}, [location]);

    useEffect(() => {
        if (!location.pathname.includes('jobs'))
            window.scrollTo(0, 0);
    }, [location]);

    return (
        <Suspense fallback={<div />}>
            <Routes>
                <Route exact path='/' element={<Default />} />
                <Route exact path='/login' element={<Login />} />
                <Route exact path='/register' element={<Register />} />
                <Route exact path='/resetPassword' element={<ResetPassword />} />
                <Route path='properties' element={<Properties />}>
                    <Route path=':state' element={<Properties />} >
                        <Route path=':grpId' element={<Properties />} />
                    </Route>
                </Route>
                <Route path='/about' element={<About />} />
                <Route path='/about/acquisitionhistory' element={<AcquisitionHistoryPage />} />
                <Route path='/about/companyresume' element={<CompanyResumePage />} />
                <Route path='/jobs' element={<Jobs />} />
                <Route path='/careers' element={<Career />} />
                <Route path='/contact' element={<ContactUs />} />
                <Route path='/residents' element={<Residents />} />

                <Route path='investors' >
                    <Route path='quarterlyreports' element={
                        <RequireAuth type="investors" goto="quarterlyreports">
                            <QuarterlyReports />
                        </RequireAuth>} />
                    <Route path='presentations' element={
                        <RequireAuth type="investors" goto="presentations">
                            <Presentations />
                        </RequireAuth>} />
                    <Route path='letters' element={
                        <RequireAuth type="investors" goto="letters">
                            <InvestorLetters />
                        </RequireAuth>} />
                    <Route path='transferrequest'>
                        <Route path='' element={
                            <RequireAuth type="investors" goto="transferrequest">
                                <TransferRequest />
                            </RequireAuth>} />
                        <Route path='list' element={
                            <RequireAuth type="investors" goto="transferrequestlist">
                                <TransferRequestQueue />
                            </RequireAuth>} />
                        <Route path=':id' element={<RequireAuth type="investors" goto="transferrequest">
                            <TransferRequest />
                        </RequireAuth>} />
                    </Route>
                    <Route path='myk1s'>
                        <Route path='' element={
                            <RequireAuth type="investors" goto="myk1s">
                                <MyK1s />
                            </RequireAuth>} />
                    </Route>

                    <Route path='transactionhistory' element={
                        <RequireAuth type="investors" goto="transactionhistory">
                            <TransactionHistory />
                        </RequireAuth>} />

                    <Route path='ledger' element={
                        <RequireAuth type="investors" goto="ledger">
                            <Ledger />
                        </RequireAuth>} />

                    <Route path='reports' element={
                        <RequireAuth type="investors" goto="reports">
                            <Reports />
                        </RequireAuth>} />

                    <Route path='myinvestments' element={
                        <RequireAuth type="investors" goto="myinvestments">
                            <Investments />
                        </RequireAuth>} />
                    <Route path='managemyaccount' element={
                        <RequireAuth type="investors" goto="manageMyAccount">
                            <ManageMyAccount />
                        </RequireAuth>} />
                    <Route path='sitetour' element={
                        <RequireAuth type="investors" goto="siteTour">
                            <DownloadSiteTour />
                        </RequireAuth>} />
                    <Route path='squarepayment' element={<Square />}/>
                </Route>
                <Route path='admin'>
                    <Route path='usermaint' element={
                        <RequireAuth type="admin" goto="usermaint">
                            <RequireAdmin>
                                <UserMaint />
                            </RequireAdmin>
                        </RequireAuth>
                    } />
                    <Route path='DocumentViewsReport' element={
                        <RequireAuth type="admin" goto="DocumentViewsReport">
                            <RequireAdmin>
                                <DocumentViewsReport />
                            </RequireAdmin>
                        </RequireAuth>
                    } />
                    <Route path='investorchangeprocessing' element={
                        <RequireAuth type="admin" goto="investorchangeprocessing">
                            <RequireAdmin>
                                <InvestorChangeProcessing />
                            </RequireAdmin>
                        </RequireAuth>
                    } />
                    <Route path='senddocusign' element={
                        <RequireAuth type="admin" goto="senddocusign">
                            <RequireAdmin>
                                <SendDocusign />
                            </RequireAdmin>
                        </RequireAuth>
                    } />

                    <Route path='dataSyncing' element={
                        <RequireAuth type="admin" goto="datasyncing">
                            <RequireAdmin>
                                <DataSyncing />
                            </RequireAdmin>
                        </RequireAuth>
                    } />
                    <Route path='addpropertydocument' element={
                        <RequireAdmin>
                            <AddPropertyDocument />
                        </RequireAdmin>
                    } />
                    <Route path='paymentprocessingadmin' element={
                        <RequireAdmin>
                            <PaymentProcessingAdmin />
                        </RequireAdmin>
                    } />
                </Route>

                <Route path="/Default.aspx" element={<Navigate to="/" />} />
                <Route path="/About.aspx" element={<Navigate to="/about" />} />
                <Route path="/Mission.aspx" element={<Navigate to="/about?section=About&content=Mission%20Statement" />} />
                <Route path="/MessageFromOwner.aspx" element={<Navigate to="/about?section=About&content=Owner%27s%20Message" />} />
                <Route path="/TeamMembers.aspx" element={<Navigate to="/about?section=Management" />} />
                <Route path="/OrgChart.aspx" element={<Navigate to="/about?section=Organization%20Charts&content=Overview" />} />
                <Route path="/Methods.aspx" element={<Navigate to="/about?section=Operations&content=Operations%20Philosophy" />} />
                <Route path="/CommunityInvolvement.aspx" element={<Navigate to="/about?section=About&content=Community%20Involvement" />} />
                <Route path="/CharitableGiving.aspx" element={<Navigate to="/about?section=About&content=Charitable%20Giving" />} />
                <Route path="/AnnualMeeting.aspx" element={<Navigate to="/about?section=Presentations&content=Annual%20Meeting" />} />
                <Route path="/PortfolioHistory.aspx" element={<Navigate to="/about?section=Portfolio" />} />
                <Route path="/AcqCriteria.aspx" element={<Navigate to="/about?section=Portfolio&content=Acquisition%20Criteria" />} />
                <Route path="/CaseStudies.aspx" element={<Navigate to="/about?section=Portfolio&content=Case%20Studies" />} />
                <Route path="/InvestorRequest.aspx" element={<Navigate to="/contact" />} />
                <Route path="/ContactUs.aspx" element={<Navigate to="/contact" />} />
                <Route path="/Jobs.aspx" element={<Navigate to="/jobs" />} />
                <Route path="/JobPosting.aspx" element={<Navigate to="/jobs" />} />
                <Route path="/careers.aspx" element={<Navigate to="/careers" />} />
                <Route path="/PropertySearch.aspx" element={<Navigate to="/properties" />} />
                <Route path="/PropertiesList.aspx" element={<Navigate to="/properties" />} />
                <Route path="/PropertiesListState.aspx" element={<Navigate to="/properties" />} />
                <Route path="/PayRent.aspx" element={<Navigate to="/residents" />} />
                <Route path="/MIMGAcquisitionHistoryPDF.aspx" element={<Navigate to="/about/acquisitionhistory"/>} />

                <Route path='/Investor/*' element={<InvestorRedirect />} />
                <Route path='/MIMGAdmin/*' element={<InvestorRedirect />} />
                <Route path='/Account/*' element={<InvestorRedirect />} />
                <Route path='/Images/*' element={<InvestorRedirect />} />
                <Route path='/Document/*' element={<InvestorRedirect />} />
                <Route path='/Documents/*' element={<InvestorRedirect />} />
                <Route path='/Scripts/*' element={<InvestorRedirect />} />



                <Route path='*' element={<NotFoundPage />} />
            </Routes>
        </Suspense >
    )
}