import React, { useState } from 'react';
import { NavDropdown, Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom'
import styles from './navItems.module.scss'

export function NavDropdownTitle(props) {
    const [show, setShow] = useState(false);
    return (
        <NavDropdown show={show} onMouseEnter={() => setShow(!show)} onMouseLeave={() => setShow(!show)} title={props.title} active={props.active}>
            {props.children}
        </NavDropdown>
    );
}

export function NavDropdownItem(props) {
    let className = props.bold ? `${styles.bold} ${styles.linkWrapper}` : styles.linkWrapper;
    return (
        <div className={props.className}>
            <NavDropdown.Item as={NavLink} to={props.link} onClick={props.onClick}>
                <div className={className}>{props.icon}{props.title}</div>
            </NavDropdown.Item>
        </div>
    )
}

export function NavDropdownItemHeader(props) {
    return (
        <NavDropdown.Item className={styles.dropDownHeader}>
            {props.title}
        </NavDropdown.Item>
    )
}

export function NavLinkItem(props) {
    return (
        <Nav.Link as={NavLink} to={props.link} onClick={props.onClick}>{props.title}</Nav.Link>
    )
}