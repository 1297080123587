export let categories = {
    about: "About",
    management: "Management",
    operations: "Operations",
    organization: "Organization Charts",
    portfolio: "Portfolio",
    allCategories: function () {
        return ([
            this.about,
            this.management,
            this.operations,
            this.organization,
            this.portfolio,
        ])
    }
}

export let adminTabs = 
{
    userMaint: "UserMaint",

    allTabs: function () {
        return (
            [
                this.userMaint,
            ]
        )
    }
}

export let aboutTabs =
{
    aboutUs: "About Us",
    missionStatement: "Mission Statement",
    ownerMessage: "Owner's Message",
    charitableGiving: "Charitable Giving",
    communityGiving: "Community Involvement",
    allTabs: function () {
        return (
            [
                this.aboutUs,
                this.missionStatement,
                this.ownerMessage,
                this.charitableGiving,
                this.communityGiving
            ]
        )
    }
}


export let managementTabs =
{
    executiveTab: "Executives",
    assetManagerTab: "Asset Managers",
    deptHeadTab: "Department Heads",
    regionalManagerTab: "Regional Managers",
    seniorAnalystTab: "Sr. Analysts",
    allTabs: function () {
        return (
            [
                this.executiveTab,
                this.assetManagerTab,
                this.deptHeadTab,
                this.regionalManagerTab,
                this.seniorAnalystTab
            ]
        )
    }
}

export let managementGroups = {
    executiveGroup: "Executive",
    assetManagerGroup: "Asset Manager",
    deptHeadGroup: "Department Head",
    regionalManagerGroup: "Regional Manager",
    seniorAnalystGroup: "Senior Analysts"
}

export let operationsTabs =
{
    philosophy: "Operations Philosophy",
    rovingCrews: "Roving Crews",
    projectInspections: "Project Inspections",
    marketing: "Marketing",
    accounting: "Accounting",
    teamRelations: "Team Relations",
    recruiting: "Recruiting",
    training: "Training",
    it: "IT",
    legal: "Legal",
    corporateServices: "Corporate Services",
    allTabs: function () {
        return (
            [
                this.philosophy,
                this.rovingCrews,
                this.projectInspections,
                this.marketing,
                this.accounting,
                this.teamRelations,
                this.recruiting,
                this.training,
                this.it,
                this.legal,
                this.corporateServices
            ]
        )
    }
}


export let organizationTabs =
{
    overViewTab: "Overview",
    accountingTab: "Accounting",
    assetManagementTab: "Asset Management",
    propertyOperationsTab: "Property Operations",
    dataAnalysisTab: "Website Development and Automation",
    corporateServicesTab: "Corporate Services",
    teamRelationsTab: "Team Relations",
    itTab: "IT",
    legalTab: "Legal",
    marketingTab: "Marketing",
    recruitingTab: "Recruiting",
    trainingTab: "Training",
    humanResourcesTab: "Human Resources",

    allTabs: function () {
        return (
            [
                this.overViewTab,
                this.accountingTab,
                this.assetManagementTab,
                this.propertyOperationsTab,
                this.dataAnalysisTab,
                this.corporateServicesTab,
                this.teamRelationsTab,
                this.itTab,
                this.legalTab,
                this.marketingTab,
                this.recruitingTab,
                this.trainingTab,
                this.humanResourcesTab
            ]
        )
    }
}



export let portfolioTabs =
{
    acquisitionMap: "Portfolio History",
    acquisiontionCriteria: "Acquisition Criteria",
    caseStudies: "Case Studies",
    allTabs: function () {
        return (
            [
                this.acquisitionMap,
                this.acquisiontionCriteria,
                this.caseStudies
            ]
        )
    }
}

