import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'bootstrap/dist/css/bootstrap.css';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import { unregister } from './registerServiceWorker';
import { ErrorBoundary } from './pages/Error Handling/ErrorBoundary'
import './index.module.scss'
import { CookiesProvider } from 'react-cookie';
import { AuthProvider } from './hooks/useAuth';

const rootElement = document.getElementById('root');
ReactDOM.render(
    <BrowserRouter basename={process.env.PUBLIC_URL}>
        <ErrorBoundary>
            <CookiesProvider >
                <AuthProvider>
                    <App />
                </AuthProvider>
            </CookiesProvider>
        </ErrorBoundary>
    </BrowserRouter>,
    rootElement);

unregister();

