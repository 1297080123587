import React from 'react';
import { NotificationPage } from 'react-mimg';
import logos from '../../images/logos';

export class ErrorPage extends React.Component {

    render() {
        return (
            <NotificationPage
                header="Something went wrong."
                logo={logos.greenText}
                text={
                    <p style={{ color: '#354442', textAlign: 'left', display: 'inline-block' }}>
                        <strong>
                            Please try refresh this page to reload.
                        </strong>
                    </p>
                } />
        )
    }
}